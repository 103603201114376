import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard',
		//beforeEnter: AuthRequired,
		children: [
			{
				path: 'page/app/dashboard',
				component: () => import(
					'./views/app/dashboard'
				),
			},
			{
				path: 'page/app/administrator',
				component: () => import(
					'./views/app/administrator'
				),
			},
			{
				name: 'apis',
				path: 'page/app/apis',
				component: () => import(
					'./views/app/apis'
				),
			},
			{
				name: 'deals',
				path: 'page/app/deals',
				component: () => import(
					'./views/app/deals'
				),
			},
			{
				name: 'deal',
				path: 'page/app/deal/:id?',
				component: () => import(
					'./views/app/deal'
				),
			},
			{
				name: 'events',
				path: 'page/app/events',
				component: () => import(
					'./views/app/events'
				),
			},
			{
				name: 'event',
				path: 'page/app/event/:id?',
				component: () => import(
					'./views/app/event'
				),
			},
			{
				name: 'translations',
				path: 'page/app/translations',
				component: () => import(
					'./views/app/translations'
				),
			},
			{
				name: 'management',
				path: 'page/app/management',
				component: () => import(
					'./views/app/management'
				),
			},
			{
				name: 'capital',
				path: 'page/app/capital',
				component: () => import(
					'./views/app/capital'
				),
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
		],
	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
