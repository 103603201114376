<template>
	<div>
		<template v-for="(tab, tabIndex) in layout" :key="'tab_' + tabIndex">
			<!-- Nav tabs -->
			<ul class="nav nav-tabs">
				<li class="nav-item sba-crud-table-nav-item" v-for="(page, pageIndex) in tab['tab-pages']" :key="'tab_' + tabIndex + '_page_' + pageIndex">
					<a class="nav-link sba-crud-table-nav-link" :class="{ 'active': pageIndex == 0 }" data-toggle="tab" data-bs-toggle="tab" :href="'#tab_' + tabIndex + '_page_' + pageIndex">{{ page['name'] }}</a>
				</li>
			</ul>

			<!-- Tab panes -->
			<div class="tab-content">
				<div class="tab-pane sba-crud-table-tab-pane" v-for="(page, pageIndex) in tab['tab-pages']" :id="'tab_' + tabIndex + '_page_' + pageIndex" :key="'tab_' + tabIndex + '_page_' + pageIndex" :class="{ 'active': pageIndex == 0 }">
					<template v-for="(wizardLayout, layoutType, layoutIndex) in page['content']" :key="'tab_' + tabIndex + '_page_' + pageIndex + '_layout_' + layoutIndex">
						<sba-crud-table-fields v-if="layoutType == 'fields'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-fields>
						<sba-crud-table-cards v-if="layoutType == 'cards'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-cards>
						<sba-crud-table-tabs v-if="layoutType == 'tabs'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-tabs>
						<sba-crud-table-rows v-if="layoutType == 'rows'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-rows>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	name: 'SbaCrudTableTabs',
	props: ['myTable', 'layout'],
	components: { 
		'sba-crud-table-fields': defineAsyncComponent(() => import('./Fields.vue')), 
		'sba-crud-table-cards': defineAsyncComponent(() => import('./Cards.vue')), 
		'sba-crud-table-tabs': defineAsyncComponent(() => import('./Tabs.vue')), 
		'sba-crud-table-rows': defineAsyncComponent(() => import('./Rows.vue')), 
	},
	data() {
		return {}
	},
	methods: { }
};
</script>