<template>
	<div>
		<template v-for="(field, index) in filteredFields" :key="index">
			<div class="form-group sba-crud-table-form-group" v-if="showEditField(field.name)" v-show="!myTable['modal']['btn'].noFields" :class="(field['currency'] && showEditField(field.name) ? 'sba-crud-table-currency-' + field['currency'] + '-box' : '') + ' ' + (field.customClass ? field.customClass : '')">
				<label v-if="!(field.type == 'label')" class="form-label sba-crud-table-field-label"><b>{{ toLabel(field.name) }} <span v-if="field.required">*</span></b></label>
				<div class="sba-crud-table-field-container">
					<input v-if="field.type=='datetime'" :placeholder="field.placeholder" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" type="datetime-local" v-model="myTable['modal']['data'][field.name]" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<div v-if="field.type=='password'" class="input-group">
						<input :placeholder="field.placeholder" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" :type="showPasswordField[field.name] && showPasswordField[field.name] == true ? 'text' : 'password'" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
						<div class="input-group-append">
							<button type="button" class="input-group-text" @click="toggleViewPasswordField(field.name)"><font-awesome-icon :icon="showPasswordField[field.name] ? 'eye-slash' : 'eye'" /></button>
						</div>
					</div>
					<input v-if="field.type=='number'" :placeholder="field.placeholder" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="number" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :min="field.minimal_amount" :max="field.maximum_amount" :step="field.step ? field.step : 'any'" @input="debounceInput" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<input v-if="field.type=='time'" :placeholder="field.placeholder" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="time" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<!-- v-model="myTable['modal']['data'][field.name]" -->
					<!-- OLD date - with ISO string
					<input v-if="field.type=='date'" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field')" class="form-control" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field')" class="form-control" :class="getClasses(field.name)" :value="myTable['modal']['data'][field.name] && myTable['modal']['data'][field.name].toISOString().split('T')[0]" @input="myTable['modal']['data'][field.name] = $event.target.valueAsDate" type="date" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					-->
					<input v-if="field.type=='date'" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="date" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :placeholder="field.placeholder" @input="debounceInput" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<select v-if="field.type=='options'" class="form-control sba-options-full" :class="getClasses(field.name)" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" v-model="myTable['modal']['data'][field.name]" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1">
						<option v-for="option in field.options" :key="option.id" v-bind:value="option.id">
							{{ option.label }}
						</option>
					</select>
					<!-- combobox //-->
					<input v-if="field.type=='combobox' && !field.inlineSearch" 
						@blur="checkOptions(field.name, fOptions[field.name], 'blur')" 
						@click="checkOptions(field.name, fOptions[field.name], 'click')" 
						@change="checkOptions(field.name, fOptions[field.name], 'change')" 
						@focus="checkOptions(field.name, fOptions[field.name], 'click')" 
						:disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" 
						:readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="combobox[field.name]" type="text" :list="field.name" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<!-- combobox //-->
					<input v-if="field.type=='combobox' && field.inlineSearch" 
						@blur="checkOptions(field.name, fOptions[field.name], 'blur')" 
						@click="checkOptions(field.name, fOptions[field.name], 'click')" 
						@input="debounceFunction(() => { checkOptions(field.name, fOptions[field.name], 'input'); })" 
						@change="checkOptions(field.name, fOptions[field.name], 'change')" 
						@focus="checkOptions(field.name, fOptions[field.name], 'click')" 
						:disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" 
						:readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="combobox[field.name]" type="text" :list="field.name" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<input v-if="field.type=='combobox'" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="hidden" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<span v-if="field.type=='combobox'" class="crud-table-input-icon" :class="{'crud-table-input-icon-green': myTable['modal']['data'][field.name]}">
						<font-awesome-icon :icon="myTable['modal']['data'][field.name] ? 'check-circle' : 'exclamation-circle'" />
					</span>
					<span v-if="field.type=='combobox'" class="crud-table-input-icon-delete" @click="checkOptions(field.name, field.options, 'delete')" v-show="checkOptions(field.name, field.options, 'checkDelete') && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled">
						<font-awesome-icon icon="times-circle" />
					</span>
					<span v-if="field.type=='combobox'" class="crud-table-input-icon-sync" @click="checkOptions(field.name, field.options, 'delete')" v-show="field.loading && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled">
						<font-awesome-icon icon="sync-alt" class="fa-spin" />
					</span>
					<!-- END combobox //-->
					<datalist :id="field.name">
						<option v-for="item in limitBy(fOptions[field.name] = filterBy(filterBy(filterBy(field.options, optionFilter(field)), selectedOptionFilter(field)), combobox[field.name], 'label'), field.optionLimit ? field.optionLimit : 50)" :value="item.label" :key="item.id" />
					</datalist>
					<!-- multiselect //-->
					<span v-if="field.type == 'multiselect'">
						<span v-for="option in getSelectedOptions(field.name, field.options)" :key="option.id" class="sba-multiselect-box">
							{{ option.label }}
							<span class="remove" title="remove" @click="removeSelectedOption(field.name, field.options, option)" v-if="!checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled">
								✖
							</span>
						</span>
					</span>
					<input v-if="!checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled && field.type=='multiselect'"
						@blur="checkOptions(field.name, fOptions[field.name], 'blur', true)" 
						@click="checkOptions(field.name, fOptions[field.name], 'click', true)" 
						@change="checkOptions(field.name, fOptions[field.name], 'change', true)" 
						@focus="checkOptions(field.name, fOptions[field.name], 'click', true)" 
						:disabled="checkDisabledModal(myTable['modal']['btn'], 'field', true) || field.disabled" 
						:readonly="checkDisabledModal(myTable['modal']['btn'], 'field', true) || field.disabled" 
						class="form-control" :class="getClasses(field.name)"
						v-model="combobox[field.name]" :placeholder="myTable.blnTranslation ? $t('crud-table.add-option') : myTable.labels.addOption" type="text" :list="field.name" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<input v-if="field.type=='multiselect'" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="hidden" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<span v-if="field.type=='multiselect'" class="crud-table-input-icon fa" :class="{'fa-exclamation-circle': !myTable['modal']['data'][field.name], 'fa-check-circle': myTable['modal']['data'][field.name], 'crud-table-input-icon-green': myTable['modal']['data'][field.name]}"></span>
					<span v-if="field.type=='multiselect'" @click="checkOptions(field.name, field.options, 'delete', true)" v-show="checkOptions(field.name, field.options, 'checkDelete', true) && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled" class="crud-table-input-icon-delete">
						<font-awesome-icon icon="times-circle" />
					</span>
					
					<!-- END multiselect //-->
					<input v-if="field.type=='text' || !field.type" :maxlength="field.max_length" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" type="text" :required="field.required" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :placeholder="field.placeholder" @input="debounceInput" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<template v-if="field.type=='textarea'">
						<textarea v-if="!field.wysiwyg" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="form-control" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" :rows="myTable['fieldDetails'][field.name] && myTable['fieldDetails'][field.name]['rows'] ? myTable['fieldDetails'][field.name]['rows'] : 4" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" @input="debounceInput" :placeholder="field.placeholder" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1"></textarea>
						<!-- Tiny MCE editor -->
						<editor 
							v-if="field.wysiwyg && field.wysiwyg == 'tinymce' && !checkDisabledModal(myTable['modal']['btn'], 'field') && !field.disabled" 
							api-key="4zxw4rhyxmkuryqjg8dv24p5febzzwja2qawpt3ij1hiz1vy" 
							:init="Object.assign({
								height: 250,
								menubar: true, 
								init_instance_callback: function(editor) {
									editor.on('blur', function(e) {
										checkRequired();
										changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)
									});
								}
							}, (field.wysiwygOptions ? field.wysiwygOptions : {}))" 
							v-model="myTable['modal']['data'][field.name]" 
							:tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1"
						/>
						<div class="readonlyWysiwyg" v-if="field.wysiwyg && (checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled)" v-html="myTable['modal']['data'][field.name]"></div>
					</template>
					<input v-if="field.type=='boolean'" type="checkbox" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" class="crud-table-move-left crud-table-checkbox" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<input v-if="field.type=='standard_file'" type="file" :name="myTable['modal']['data'][field.name]" :accept="myTable['modal']['data'][field.accept]" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1" />
					<!--- Options --->
					<select v-if="field.type=='enum'" class="form-control sba-options-full" :class="getClasses(field.name)" v-model="myTable['modal']['data'][field.name]" :disabled="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" :readonly="checkDisabledModal(myTable['modal']['btn'], 'field') || field.disabled" @change="checkRequired();changeFieldTrigger(field.name, myTable['modal']['data'][field.name], 'change', false)" :tabindex="(myTable.tabIndexFrom ? myTable.tabIndexFrom : 0) + parseInt(index) + 1">
						<option v-for="option in myTable['fieldDetails'][field.name].options" :value="option.id" :key="option.id">
							{{ option.name }}
						</option>
					</select>
					<template v-if="field.type=='file'">
						<!-- <vue-dropzone :ref="field.name + '-input'" :id="'input_' + field.name" :options="{...field.dropzoneOptions, 'maxFiles': (field.multiple == '1' ? null : 1)}" @vdropzone-success="onFileChange(...arguments, field)" @vdropzone-removed-file="onFileDeleted(...arguments, field)" :destroyDropzone="false"></vue-dropzone> -->
						<sba-dropzone :ref="field.name + '-input'" :id="'input_' + field.name" v-model="myTable['modal']['data'][field.name]" :options="field.dropzoneOptions"></sba-dropzone>
					</template>
					<div v-show="field.type=='table'">
						<SbaCrudTable v-if="field.table" :name="field.name" :key="field.name" :my-table="field.table" />
					</div>
					<h6 class="sba-crud-table-field-label" v-if="field.type=='label'" :class="[field.customClass ? field.customClass : '']">
						<b>{{ toLabel(field.name) }}</b>
					</h6>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
import Editor from '@tinymce/tinymce-vue';
import SBADropzone from '@/components/SBADropzone.vue';

export default {
	name: 'SbaCrudTableFields',
	props: ['myTable', 'layout'],
	components: {
		Editor,
		'sba-dropzone': SBADropzone,
	},
	data() {
		return {
			combobox: {},
			multiselect: {},
			fOptions: {},
			debounceFunction: this.createDebounce(),
			showPasswordField: {},
		}
	},
	mixins: [Vue2Filters.mixin],
	
	created() {
		// create watch for dropzone files
		if (Array.isArray(this.layout) && this.layout.length > 0
			&& Array.isArray(this.myTable.modal.fields) && this.myTable.modal.fields.length > 0) {
			this.myTable.modal.fields.forEach((field) => {
				// initiate combobox option before mount view
				if (this.layout.indexOf(field.name) >= 0 && field.type == 'combobox') {
					this.checkOptions(field.name, field.options, 'init');
				}
			});
		}
	},
	
	methods: {
		// debounce function
		createDebounce: function() {
			let timeout = null;
			return function (fnc, delayMs) {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					fnc();
				}, delayMs || 500);
			};
		},
		checkRequired: function() {
			if(!this.myTable.modal.btn){
				return;
			}
			let blnSubmit = false;
			this.myTable.modal.fields.forEach((field) => {
				
				if (blnSubmit) {
					return;
				}
				
				if (!this.showEditField(field.name)) {
					return;
				}

				if (!this.myTable.fieldDetails) {
					this.myTable.fieldDetails = {};
				}
				
				if (!this.myTable.modal.data) {
					this.myTable.modal.data = {};
				}
				
				if (field.required && (this.myTable.modal.data[field.name] === undefined
					|| (this.myTable.modal.data[field.name] !== undefined
					&& (this.myTable.modal.data[field.name] === null
					|| this.myTable.modal.data[field.name] === ""
					|| (field.type == 'options' && this.myTable.modal.data[field.name] == 0)
					|| (field.type == 'combobox' && this.myTable.modal.data[field.name] == 0)
					|| (field.type == 'number' && isNaN(this.myTable.modal.data[field.name])))))) {
					this.myTable.modal.btn.description = this.myTable.blnTranslation ? this.myTable.translate('crud-table.warning-required.label', { label: field.label }) : this.myTable.labels.warningRequiredStartLabel + field.label + this.myTable.labels.warningRequiredEndLabel;
					blnSubmit = true;
				}
			});
			if(!blnSubmit){
				this.myTable.modal.btn.description = '';
			}
			
			this.myTable.checkModalRequired = blnSubmit;
		},
		showEditField: function(fieldName) {
			if (this.myTable.fieldDetails && this.myTable.fieldDetails[fieldName] && this.myTable.fieldDetails[fieldName].hideEdit) {
				return false;
			} else {
				// check conditional visibility
				if (this.myTable.fieldDetails && this.myTable.fieldDetails[fieldName] && this.myTable.fieldDetails[fieldName].visibility) {
					// from object to array
					if (typeof this.myTable.fieldDetails[fieldName].visibility === 'object' && !Array.isArray(this.myTable.fieldDetails[fieldName].visibility)) {
						this.myTable.fieldDetails[fieldName].visibility = [this.myTable.fieldDetails[fieldName].visibility];
					}
					
					// initiate show
					let isShow = true;
					
					// validate visibility
					if (Array.isArray(this.myTable.fieldDetails[fieldName].visibility)) {
					
						// loop trough visibility
						this.myTable.fieldDetails[fieldName].visibility.forEach((visibility) => {
							let isVisible = true;
							
							//----------[compare]----------//
							switch(visibility.operator) {
								case '<>':
									isVisible = (this.myTable.modal.data[visibility.field] != visibility.value);
									break;
								case '!=':
									isVisible = (this.myTable.modal.data[visibility.field] != visibility.value);
									break;
								case '==':
									isVisible = (this.myTable.modal.data[visibility.field] == visibility.value);
									break;
								case '>=':
									isVisible = (this.myTable.modal.data[visibility.field] >= visibility.value);
									break;
								case '<=':
									isVisible = (this.myTable.modal.data[visibility.field] <= visibility.value);
									break;
								case '>':
									isVisible = (this.myTable.modal.data[visibility.field] > visibility.value);
									break;
								case '<':
									isVisible = (this.myTable.modal.data[visibility.field] < visibility.value);
									break;
								default:
									isVisible = true;
									break;
							}
							
							if (visibility.deny !== undefined && visibility.deny) {
								isVisible = !isVisible;
							}
							
							isShow &= isVisible;
						});
					}
					
					return isShow;
				}
				
				return true;
			}
		},
		toggleViewPasswordField: function(fieldName) {
			if (this.showPasswordField[fieldName] == undefined) {
				this.showPasswordField[fieldName] = false;
			}
			this.showPasswordField[fieldName] = !this.showPasswordField[fieldName];
		},
		// debounce input
		debounceInput: function() {
			return this.debounce(function (e) {
				this.$store.dispatch('updateInput', e.target.value);
			}, 300);
		},
		debounce: function(func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments;
				var later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},
		/*----------[field filter]------*/
		toLabel: function(fieldName) {
			if(this.myTable['fieldDetails'] !== undefined && this.myTable['fieldDetails'][fieldName] !== undefined && this.myTable['fieldDetails'][fieldName].label !== undefined){
				return this.myTable['fieldDetails'][fieldName].label;
			}else{
				return fieldName.split("_").join(" ");
			}
		},
		changeFieldTrigger: function(fieldName, fieldValue, eventType) {
			// call change field trigger in parent
			if (this.myTable.changeFieldTrigger !== undefined) {
				this.myTable.changeFieldTrigger(fieldName, fieldValue, eventType);
			}
		},
		
		checkOptions: function(fieldName, fieldOptions, eventType, multiselect) {
			if (!multiselect) {
				multiselect = false;
			}
			
			let init = false;
			if(this.combobox[fieldName] === undefined){
				init = true;
				this.combobox[fieldName] = null;
			}
			
			//filter
			let objFilter = [];
			let fieldDetails = {};
			let searchObject = {};
			
			//field
			let field = {};
			field.options = fieldOptions;
			field.name = fieldName;
			field.value = this.myTable['modal']['data'][field.name];
			field.label = this.getOptionLabel(field);
			
			switch (eventType) {
				case 'delete':
					if (multiselect) {
						this.multiselect[field.name] = [];
						this.myTable['modal']['data'][field.name] = [];
					} else {
						this.myTable['modal']['data'][field.name] = null;
					}
					field.value = null;
					this.combobox[field.name] = "";
					this.resetOption(field);
					
					//update to parent scope
					this.changeFieldTrigger(fieldName, objFilter[0], eventType);
					break;
				case 'checkDelete':
					//initiate
					if (init) {
						this.checkOptions(fieldName, fieldOptions, 'init');
					}
					if (this.combobox[field.name] !== undefined && this.combobox[field.name] !== null && this.combobox[field.name].length > 0 && field.label) {
						return true;
					} else {
						return false;
					}
				case 'click':
					if (!field.label) {
						this.combobox[field.name] = "";
					}
					break;
				case 'input':
					// validate single result
					objFilter = this.filterBy(field.options, this.combobox[field.name], 'label');
					if (objFilter.length >= 1) {
						// validate single result is exact
						objFilter = objFilter.filter((x) => {
							return x.label === this.combobox[field.name];
						});
						if (objFilter.length == 1) {
							return;
						}
					}
					
					// find field details
					fieldDetails = this.myTable['modal']['fields'].find((modalField) => {
						return modalField.name == field.name;
					});
					// check field details
					if (fieldDetails !== undefined && typeof fieldDetails === 'object' && fieldDetails.inlineSearch) {
						fieldDetails.loading = true;
					
						// build search object
						searchObject = {
							controller: fieldDetails.inlineSearch.controller,
							filters: [],
						};
						// set function
						if (fieldDetails.inlineSearch.function) {
							searchObject['function'] = fieldDetails.inlineSearch.function;
						}
						// set view
						if (fieldDetails.inlineSearch.view) {
							searchObject['view'] = fieldDetails.inlineSearch.view;
						}
						// set limit
						if (fieldDetails.inlineSearch.limit !== undefined && !isNaN(fieldDetails.inlineSearch.limit)) {
							searchObject['limit'] = fieldDetails.inlineSearch.limit;
						} else {
							searchObject['limit'] = 500;
						}
						// validate default filters
						if (fieldDetails.inlineSearch.defaultFilters !== undefined && Array.isArray(fieldDetails.inlineSearch.defaultFilters)) {
							fieldDetails.inlineSearch.defaultFilters.forEach((defaultFilter) => {
								if (typeof defaultFilter === 'object' && defaultFilter.name && defaultFilter.value) {
									searchObject['filters'].push(defaultFilter);
								}
							});
						}
						// set filter value
						if (this.combobox[field.name]) {
							let searchStrict = false;
							let searchField = fieldDetails.inlineSearch.field;
							let searchValue = this.combobox[field.name];
							if (typeof this.combobox[field.name] == 'string') {
								// regex for ID
								const regex = /^([0-9]{1,})\s*-\s*/i;
								const results = this.combobox[field.name].match(regex);
								
								// ID
								if (results && results[1] !== undefined && !isNaN(results[1])) {
									searchStrict = true,
									searchField = 'id';
									searchValue = results[1];
								}
							}
							searchObject['filters'].push({
								name: searchField,
								value: searchValue,
								strict: searchStrict,
							});
						}
						// process data request
						this.myTable.processDataRequest(searchObject).then((response) => {
							if (response.status) {
								// initiate change to
								if (fieldDetails.inlineSearch.changeTo === undefined || !Array.isArray(fieldDetails.inlineSearch.changeTo)) {
									fieldDetails.inlineSearch.changeTo = ['id', ' - ', 'name'];
								}
								
								// build translate fields
								let translateFields = [];
								if (fieldDetails.translateFields !== undefined && fieldDetails.translateFields) {
									if (Array.isArray(fieldDetails.translateFields) && fieldDetails.translateFields.length) {
										translateFields = fieldDetails.translateFields;
									}
									if (typeof fieldDetails.translateFields === 'string' && fieldDetails.translateFields.length) {
										translateFields.push(fieldDetails.translateFields);
									}
								}
								
								// generate labels for options
								response.data.forEach((row) => {
									row['label'] = fieldDetails.inlineSearch.changeTo.reduce((previousValue, currentValue) => {
										if (translateFields.includes(currentValue)) {
											return previousValue + (row[currentValue] !== undefined && row[currentValue] !== null ? this.myTable.translate(row[currentValue]) : this.myTable.translate(currentValue));
										} else {
											return previousValue + (row[currentValue] !== undefined && row[currentValue] !== null ? row[currentValue] : currentValue);
										}
									}, '');
								});
								fieldDetails.options = response.data;
								
								if (fieldDetails.options.length == 1) {
									//update to parent scope
									this.checkOptions(fieldName, fieldDetails.options, 'blur');
								}
							} else {
								fieldDetails.options = [];
							}
							
							fieldDetails.loading = false;
						}, (error) => {
							fieldDetails.options = [];
							
							fieldDetails.loading = false;
						});
					}
					break;
				case 'change':
					objFilter = this.filterBy(field.options, this.combobox[field.name], 'label');
					if (objFilter.length == 1) {
						if (multiselect) {
							// must equal exactly to add
							if (this.multiselect[field.name] === undefined) {
								// rewrite from this.$set
								this.multiselect[field.name] = [];
							}
							if (objFilter[0]['label'] == this.combobox[field.name]) {
								this.multiselect[field.name].push(objFilter[0].id);
								this.myTable['modal']['data'][field.name] = this.multiselect[field.name];
							}
						} else {
							this.myTable['modal']['data'][field.name] = objFilter[0].id;
						}
					
						//update to parent scope
						this.changeFieldTrigger(fieldName, objFilter[0], eventType);
					} else {
						if (!multiselect) {
							this.myTable['modal']['data'][field.name] = null;
						}
					}
					this.checkRequired();
					break;
				case 'init':
					// find field details
					fieldDetails = this.myTable['modal']['fields'].find((modalField) => {
						return modalField.name == field.name;
					});
					// check field details
					if (fieldDetails !== undefined && typeof fieldDetails === 'object' && fieldDetails.inlineSearch) {
						fieldDetails.loading = true;
					
						// build search object
						searchObject = {
							controller: fieldDetails.inlineSearch.controller,
							filters: [],
						};
						// set function
						if (fieldDetails.inlineSearch.function) {
							searchObject['function'] = fieldDetails.inlineSearch.function;
						}
						// set view
						if (fieldDetails.inlineSearch.view) {
							searchObject['view'] = fieldDetails.inlineSearch.view;
						}
						// set limit
						if (fieldDetails.inlineSearch.limit !== undefined && !isNaN(fieldDetails.inlineSearch.limit)) {
							searchObject['limit'] = fieldDetails.inlineSearch.limit;
						} else {
							searchObject['limit'] = 500;
						}
						
						// validate default filters
						if (fieldDetails.inlineSearch.defaultFilters !== undefined && Array.isArray(fieldDetails.inlineSearch.defaultFilters)) {
							fieldDetails.inlineSearch.defaultFilters.forEach((defaultFilter) => {
								if (typeof defaultFilter === 'object' && defaultFilter.name && defaultFilter.value) {
									searchObject['filters'].push(defaultFilter);
								}
							});
						}
						
						// set filter value
						if (field.value) {
							searchObject['filters'].push({
								name: 'id',
								value: field.value,
							});
						}
						
						// process data request
						this.myTable.processDataRequest(searchObject).then((response) => {
							if (response.status) {
								// initiate change to
								if (fieldDetails.inlineSearch.changeTo === undefined || !Array.isArray(fieldDetails.inlineSearch.changeTo)) {
									fieldDetails.inlineSearch.changeTo = ['id', ' - ', 'name'];
								}
								
								// build translate fields
								let translateFields = [];
								if (fieldDetails.translateFields !== undefined && fieldDetails.translateFields) {
									if (Array.isArray(fieldDetails.translateFields) && fieldDetails.translateFields.length) {
										translateFields = fieldDetails.translateFields;
									}
									if (typeof fieldDetails.translateFields === 'string' && fieldDetails.translateFields.length) {
										translateFields.push(fieldDetails.translateFields);
									}
								}
								
								// generate labels for options
								response.data.forEach((row) => {
									row['label'] = fieldDetails.inlineSearch.changeTo.reduce((previousValue, currentValue) => {
										if (translateFields.includes(currentValue)) {
											return previousValue + (row[currentValue] !== undefined && row[currentValue] !== null ? this.myTable.translate(row[currentValue]) : this.myTable.translate(currentValue));
										} else {
											return previousValue + (row[currentValue] !== undefined && row[currentValue] !== null ? row[currentValue] : currentValue);
										}
									}, '');
								});
								fieldDetails.options = response.data;
								
								// initiate field
								field.options = response.data;
								field.label = this.getOptionLabel(field);
								
								// check field label
								if (field.label) {
									this.combobox[field.name] = field.label;
									
									objFilter = this.filterBy(field.options, this.combobox[fieldName], 'label');
									this.changeFieldTrigger(fieldName, objFilter[0], eventType);
								}
							} else {
								fieldDetails.options = [];
							}
							
							fieldDetails.loading = false;
						}, (error) => {
							fieldDetails.options = [];
							
							fieldDetails.loading = false;
						});
					}
				
					if (field.label) {
						this.combobox[field.name] = field.label;
					} else {
						this.resetOption(field);
					}
					this.checkRequired();
					break;
				case 'blur':
					objFilter = this.filterBy(field.options, this.combobox[fieldName], 'label');
					if (objFilter.length == 1) {
						if (!multiselect) {
							this.myTable['modal']['data'][field.name] = objFilter[0].id;
						}
						
						this.combobox[fieldName] = objFilter[0].label;
					} else {
						if (!multiselect) {
							this.myTable['modal']['data'][field.name] = null;
						}
						field.value = null;
					}
					this.checkRequired();
					break;
			}
		},
		
		getOptionLabel: function(field) {
			if(field.value == null || field.value == 0){
				return false;
			}

			if (Array.isArray(field.value)) {
				return false;
			}

			let result = field.options.filter((option) => { return option['id'] === field.value; });
			if(result && result.length == 1 && result[0] && result[0].label){
				return result[0].label;
			}else{
				return false;
			}
		},
		
		resetOption: function(field){
			//default
			if(field.value == 0 && field.options[0]){
				this.combobox[field.name] = field.options[0].label;
			}else if(!field.value){
				this.combobox[field.name] = this.myTable.blnTranslation ? this.myTable.translate('crud-table.select-label') : this.myTable.labels.selectLabel;
			}
		},
		checkDisabledModal: function(btn, part) {
			if(btn && btn.id){
				switch(part){
					case 'confirm':
						return (btn.id.toLowerCase() == 'r');
					case 'field':
						return (btn.id.toLowerCase() == 'r' || btn.id.toLowerCase() == 'd');
				}
			}
		},
		optionFilter: function(field) {
			if (!this.myTable.fieldDetails || (this.myTable.fieldDetails && !this.myTable.fieldDetails[field.name]) || (this.myTable.fieldDetails && this.myTable.fieldDetails[field.name] &&!this.myTable.fieldDetails[field.name].optionFilter)) {
				return function(item) {
					return true;
				};
			}
			return (item) => {
				let filters = this.myTable.fieldDetails[field.name].optionFilter;
				if (!Array.isArray(filters)) {
					filters = [filters];
				}

				let show = true;
				filters.forEach((filter) => {
					if (filter.ffield === undefined || filter.lfield === undefined || filter.controller === undefined || this.myTable.data[filter.controller] === undefined) {
						return;
					}
					
					if (filter.link === undefined) {
						//lfield - > subtable field
						//ffield - > current row
						let fltOptions = this.myTable.data[filter.controller].filter((row) => {
							return row['id'] == item['id'] && row[filter.lfield] == this.myTable.modal.data[filter.ffield];
						});
						show = show && (fltOptions && fltOptions[0]);
					} else {
						if (this.myTable.data[filter.link] === undefined || this.myTable.subQueries[filter.link] === undefined || this.myTable.subQueries[filter.link].lfield === undefined || this.myTable.subQueries[filter.link].ffield === undefined) {
							return;
						}
						
						//lfield - > subtable field
						//ffield - > current row
						//link - > link table
						let linkOptions = this.myTable.data[filter.link].filter((row) => {
							return this.myTable.modal.data[this.myTable.subQueries[filter.link].lfield] == row[this.myTable.subQueries[filter.link].ffield];
						}).map((row) => {
							return row[filter.ffield];
						});
						
						let fltOptions = this.myTable.data[filter.controller].filter((row) => {
							return row['id'] == item['id'] && linkOptions.indexOf(row[filter.lfield]) > -1;
						});
						show = show && (fltOptions && fltOptions[0]);
					}

				});
				return show;
			}
		},
		getClasses: function(fieldName){
			let strClasses = "";
			if (this.myTable['fieldDetails'] && this.myTable['fieldDetails'][fieldName] && this.myTable['fieldDetails'][fieldName]['class'] !== undefined && Array.isArray(this.myTable['fieldDetails'][fieldName]['class'])) {
				let objClass = this.myTable['fieldDetails'][fieldName].class;
				objClass.forEach((strClass) => {
					strClasses = strClasses + ' ' + strClass;
				});
			}
			return strClasses;
		},
		getSelectedOptions: function(fieldName, fieldOptions) {
			// initiate when first called on new model
			if (this.multiselect[fieldName] == undefined && Array.isArray(this.myTable['modal']['data'][fieldName])) {
				// rewrite from this.$set
				this.multiselect[fieldName] = this.myTable['modal']['data'][fieldName].slice();
			}
			
			if (!Array.isArray(this.multiselect[fieldName])) {
				return [];
			}

			let result = fieldOptions.filter((option) => {
				return this.multiselect[fieldName].indexOf(option.id) !== -1;
			});

			if (result && result.length) {
				return result;
			} else {
				return [];
			}
		},
		removeSelectedOption: function(fieldName, fieldOptions, option) {
			if (!Array.isArray(this.multiselect[fieldName]) || !option || !option.id) {
				return false;
			}

			let index = this.multiselect[fieldName].indexOf(option.id);
			if (index !== -1) {
				this.multiselect[fieldName].splice(index, 1);
			}
			
			this.myTable['modal']['data'][fieldName] = this.multiselect[fieldName];

			return true;
		},
		selectedOptionFilter: function(field) {
			let value = this.myTable['modal']['data'][field.name];

			if (!Array.isArray(value)) {
				return function(option) {
					return true;
				}
			}

			return function(option) {
				return option && option.id && value.indexOf(option.id) === -1;
			}
		},
		onFileChange: function (file, response, field) {
			//check for status
			if (response.status) {
				//check for multiple
				if (field['multiple'] && field['multiple'] == '1') {
					//set file in array
					if (this.myTable['modal']['data'][field.name] == undefined || this.myTable['modal']['data'][field.name].length <= 0 ) {
						// rewrite from this.$set
						this.myTable['modal']['data'][field.name] = [];
					}
					this.myTable['modal']['data'][field.name].push(response.data.file_name);
				} else {
					//set file_name in table
					// rewrite from this.$set
					this.myTable['modal']['data'][field.name] = response.data.file_name;
				}
			} else {
				//remove file from input
				this.$refs[field.name + '-input'][0].removeFile(file);
			}
		},
		onFileDeleted: function(file, field) {
			// remove file from external
			if (field.deleteFileUrl != undefined) {
				let fileName = file['name'];
				if (file['externalFileName'] !== undefined) {
					fileName = file['externalFileName'];
				}
				let url = (process.env !== undefined ? process.env.VUE_APP_API_URL : import.meta.env.VITE_API_URL) + field.deleteFileUrl + '/' + fileName;
				this.$http.get(url).then((response) => {
					if (response.status) {
						//check for multiple
						if (this.myTable['modal']['data'][field.name] && typeof this.myTable['modal']['data'][field.name] == 'object') {
							//look for file in array
							for (let index in this.myTable['modal']['data'][field.name]) {
								//check on filename
								if (this.myTable['modal']['data'][field.name][index] == fileName) {
									delete this.myTable['modal']['data'][field.name][index];
								}
							}
						} else if (this.myTable['modal']['data'][field.name]) {
							// set null to update database
							// rewrite from this.$set
							this.myTable['modal']['data'][field.name] = null;
						}
					}
				});
			}
		},
		dropzoneTemplate:function () {
			return `
				<div class="dz-preview dz-file-preview mb-3">
					<div class="d-flex flex-row ">
						<div class="p-0 w-30 position-relative">
							<div class="dz-error-mark"><span><i></i></span></div>
							<div class="dz-success-mark"><span><i></i></span></div>
							<div class="preview-container">
								<img data-dz-thumbnail class="img-thumbnail border-0" />
								<i class="simple-icon-doc preview-icon"></i>
							</div>
						</div>
						<div class="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
							<div> <span data-dz-name /> </div>
							<div class="text-primary text-extra-small" data-dz-size></div>
						</div>
						<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
						<div class="dz-error-message"><span data-dz-errormessage></span></div>
					</div>
					<a href="#" class="remove" data-dz-remove> <i class="glyph-icon simple-icon-trash"></i> </a>
				</div>
			`;
		}
	},
	
	computed: {
		filteredFields: function() {
			let objFields = Object.assign({}, this.myTable['modal']['fields']);
			
			Object.keys(objFields).map(function(key, index) {
				if (this.layout.indexOf(objFields[key].name) === -1) {
					delete objFields[key]
				}
			}, this);
			
			return objFields;
		}
	}
};
</script>

<style scoped>
	input.sba-full {
		margin:	0;
		/*height: 100%;*/
		display: inline-block;
		width: 100%;
		padding: 0;
	}
	
	input.sba-half {
		margin: 0;
		display: inline-block;
		width: 50%;
		padding: 0;
		float: left;
	}
	
	select.sba-options-full {
		width: 100% !important;
	}
	
	table.crud-table th{
		text-transform: capitalize;
	}
	
	div.crud-table-panel{
		height: 34px;
	}
	
	div.crud-table-footer-statistics{
		font-size: 11px;
		float:left;
	}
	
	input.crud-table-checkbox{
		margin: 10px 0px;
	}
	
	div.crud-table-panel-text{
		float:right;
		font-size:11px;
	}
	
	tr.crud-table-statistics td{
		font-weight: bold;
	}
	
	tr.crud-table-statistics{
		border-top: 2px solid #ddd;

	}
	
	span.crud-table-message{
		padding:2px;
		border-radius:2px;
		margin:0px;
		background:green;
		color: white;
		float: right;
		font-size: 10px;
	}
	
	div.crud-table-modal-message{
		padding:2px;
		border-radius:2px;
		margin:0px;
		color: white;
		text-align: center;
		font-size: 16px;
	}
	
	.crud-table-bg-red{
		background: red;
	}
	
	.crud-table-bg-green{
		background: green;
	}
	
	span.crud-table-refresh{
		border: 1px solid grey;
		padding: 2px;
		margin-left: 10px;
	}
	
	span.crud-table-refresh:hover{
		border: 1px solid grey;
		padding: 2px;
		font-weight: bold;
		cursor: pointer;
	}
	
	div.panel-heading{
		font-weight: bold;
	}
	
	div.crud-table-pagination{
		padding: 2px;
		text-align:	center;
	}
	
	div.crud-table-pagination ul{
		margin: 0px;
		flex-wrap: wrap;
	}
	
	.crud-table-right{
		float:right;
	}
	
	.crud-table-left{
		float:left;
	}
	
	.crud-table-divide-before{
		border-left: 1px solid #cecece;
		padding-left: 6px;
		padding-right: 6px;
	}
	
	.crud-table-spacing-after{
		padding-right: 6px;
	}
	
	input.crud-table-search{
		font-size: 10px;
		margin-left: 4px;
		color: black;
		margin-right: 6px;
	}
	
	.crud-table-sortorder:after {
		content: '\25b2';
	}
	
	.crud-table-sortorder.reverse:after {
		content: '\25bc';
	}
	
	th.crud-table-th:hover{
		cursor: pointer;
	}
	
	td.crud-table-button{
		display: table-cell !important;
	}
	
	button.crud-table-button-width{
		width: auto !important;
	}
	
	td.crud-table-button:hover{
		color: red;
		cursor: pointer;
	}
	
	div.crud-table-button-row{
		padding: 2px;
		color: #333;
		background-color: #f5f5f5;
		border-color: #ddd;
	}
	
	button.crud-table-button:hover{
		cursor: pointer;
	}
	
	button.crud-table-button{
		margin: 2px;
		width: 28px;
		height: 28px;
	}
	
	.crud-table-modal {
		display: block;
		background: #cecece;
		background: rgba(240,240,240,0.8);
	}
	
	.crud-table-modal-body:before,.crud-table-modal-body:after {
		display: table;
		content: " ";
	}
	
	/*
	.crud-table-modal-header:before,.crud-table-modal-header:after {
		display: table;
		content: " ";
	}
	*/
	
	th.crud-table-shrink, td.crud-table-shrink{
		white-space: nowrap;
		width: 50px;
	}
	
	div.crud-table-modal-th{
		padding: 5px;
		padding-top: 7px;
		text-align: right;
		font-weight: bold;
		text-transform: capitalize;
	}
	
	.crud-table-move-left {
		width: auto;
		box-shadow: none;
	}
	
	.crud-table-input-icon{
		float: right;
		margin-right: 35px;
		margin-top: -30px;
		position: relative;
		z-index: 2;
		color: red;
	}
	
	.crud-table-input-icon-delete{
		float: right;
		margin-right: 52px;
		margin-top: -30px;
		position: relative;
		z-index: 2;
		color: gray;
	}
	
	.crud-table-input-icon-sync{
		float: right;
		margin-right: 69px;
		margin-top: -24px;
		position: relative;
		z-index: 2;
		color: gray;
	}
	
	/* Define an animation behavior */
	@keyframes spinner {
		to { transform: rotate(360deg); }
	}
	
	/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
	.fa-spin {
		/* Apply 'spinner' keyframes looping once every second (1s) */
		animation: spinner 2s linear infinite;
	}
	
	.crud-table-input-icon-green{
		color: green;
	}
	
	.crud-table-modal-scroll{
		overflow-y: auto;
	}
	
	tr.crud-table-filter-hide{
		display: none;
	}
	
	tr.crud-table-filter-show{
		display: block;
	}
	
	.crud-table-btn-active{
		color: red;
	}
	
	@media (min-width: 1000px){
	
		.crud-table-modal-dialog {
			width: 850px;
		}
		
		.crud-table-modal-th{
			width: 20%;
		}
		
		.crud-table-modal-tв{
			width: 70%;
		}
	}
	
	@media only screen and (max-width: 800px) {
	
		/* Force table to not be like tables anymore */
		#crud-table-no-more-tables table,
		#crud-table-no-more-tables thead,
		#crud-table-no-more-tables tfoot,
		#crud-table-no-more-tables tbody,
		#crud-table-no-more-tables th,
		#crud-table-no-more-tables td,
		#crud-table-no-more-tables tr {
			display: block;
		}

		#crud-table-no-more-tables table {
			empty-cells: show;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		#crud-table-no-more-tables thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		#crud-table-no-more-tables tr {
			border: 1px solid #ccc;
		}

		#crud-table-no-more-tables td {
			/* Behave like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
			white-space: normal;
			text-align:left;
			word-wrap: break-word;
			min-height: 51px;
		}

		#crud-table-no-more-tables td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0px;
			left: 6px;
			width: 45%;
			/*padding-right: 10px; */
			white-space: nowrap;
			text-align:left;
			font-weight: bold;
			text-transform: capitalize;
			padding: 16px 10px;
		}

		/*
		Label the data
		*/
		#crud-table-no-more-tables td:before {
			content: attr(data-title);
		}

		#crud-table-empty{
			display:none !important;
		}
		
		button.crud-table-button, button.crud-table-button-header {
			border-radius: 0px;
			-webkit-appearance: none;
		}
		
		td.crud-table-shrink{
			width: auto;
		}
	}
	
	span.sba-multiselect-box .remove {
		cursor: pointer;
	}
	
	span.sba-multiselect-box {
		display: inline-block;
		padding: 4px;
		background-color: lightgrey;
		border-radius: 2px;
		margin: 4px 4px;
		border: 1px solid grey;
	}
	
	.readonlyWysiwyg{
		background-color: #e9ecef;
		margin: 0;
		padding: 10px;
		border: 1px solid #d7d7d7;
		white-space: pre-wrap;
	}
	
</style>