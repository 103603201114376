<template>
	<div>
		<div class="row" v-for="(row, index) in layout" :key="'row_' + index">
			<div class="col-12 col-lg" v-for="(col, colIndex) in row['cols']" :key="'row_' + index + '_col_' + colIndex">
				<template v-for="(wizardLayout, layoutType, layoutIndex) in col" :key="'row_' + index + '_col_' + colIndex + '_layout_' + layoutIndex">
					<sba-crud-table-fields v-if="layoutType == 'fields'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-fields>
					<sba-crud-table-cards v-if="layoutType == 'cards'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-cards>
					<sba-crud-table-tabs v-if="layoutType == 'tabs'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-tabs>
					<sba-crud-table-rows v-if="layoutType == 'rows'" :layout="wizardLayout" :myTable="myTable"></sba-crud-table-rows>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	name: 'SbaCrudTableRows',
	props: ['myTable', 'layout'],
	components: { 
		'sba-crud-table-fields': defineAsyncComponent(() => import('./Fields.vue')), 
		'sba-crud-table-cards': defineAsyncComponent(() => import('./Cards.vue')), 
		'sba-crud-table-tabs': defineAsyncComponent(() => import('./Tabs.vue')), 
		'sba-crud-table-rows': defineAsyncComponent(() => import('./Rows.vue')), 
	},
	data() {
		return {}
	},
	methods: { }
};
</script>