<template>
	<div :ref="options.name" :class="options.name + ' dropzone'"></div>
</template>
<script>
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

export default {
	name: 'SBA-Dropzone',
	props: ['options', 'modelValue'],
	emits: ['update:modelValue'],
	mounted () {
		let paramName = 'file';
		if (this.options.paramName !== undefined) {
			paramName = this.options.paramName;
		}
		let maxFiles = 1;
		if (this.options.multiple) {
			maxFiles = 99;
		}

		let myDropzone = new Dropzone("div." + this.options.name, { 
			url: this.options.uploadFileUrl,
			withCredentials: true,
			paramName: paramName,
			dictDefaultMessage: this.options.description,
			maxFiles: maxFiles,
		});
		myDropzone.on("success", (file, response) => {
			let result = JSON.parse(response);
			
			if (result.status) {
				if (this.options.multiple) {
					if (Array.isArray(this.modelValue)) {
						this.modelValue.push(result.data.file_name);
						this.$emit('update:modelValue', this.modelValue);
					} else {
						this.$emit('update:modelValue', [result.data.file_name]);
					}
				} else {
					this.$emit('update:modelValue', result.data.file_name);
				}
				
			}
		});
		myDropzone.on("addedfile", (file) => {
		
			// Check if dropzone not disabled before adding delete button
			if (this.options.disabled === undefined || !this.options.disabled) {

				// Create the remove button
				var removeButton = Dropzone.createElement('<button data-dz-remove class="btn btn-default"><span class="simple-icon-trash"></span></button>');

				// Listen to the click event
				removeButton.addEventListener("click", (e) => {
					// Make sure the button click doesn't submit the form:
					e.preventDefault();
					e.stopPropagation();
					
					//delete file on backend
					if (this.options.deleteFileUrl != undefined) {
						let url = this.options.deleteFileUrl + '/' + file.name;
						this.req('GET', url).then((response) => {
							if (response) {
								// Remove the file preview.
								myDropzone.removeFile(file);
								
								if (this.options.multiple) {
									if (Array.isArray(this.modelValue)) {
										const index = this.modelValue.indexOf(file.name);
										if (index > -1) {
											this.modelValue.splice(index, 1);
											this.$emit('update:modelValue', this.modelValue);
										}
									} else {
										// set field to null
										this.$emit('update:modelValue', []);
									}
								} else {
									// set field to null
									this.$emit('update:modelValue', "");
								}
							}
						});
					}
				});

				// Add the button to the file preview element.
				file.previewElement.appendChild(removeButton);
			}
		});
		if (this.options.multiple && this.options.multiple && this.modelValue && typeof this.modelValue == 'object') {
			Object.values(this.modelValue).forEach((fileName) => {
				let url = this.options.getFileUrl + '/' + fileName;
				let reader  = new FileReader();
				this.req('GET', url).then((response) => {
					if (response) {
						let blob = new Blob();
						let file = new File([response.body], fileName);
						let fileDetail = { size: file.size, name: file.name };
						myDropzone.emit("addedfile", fileDetail);
						myDropzone.emit("thumbnail", fileDetail, url);
						myDropzone.emit("complete", fileDetail);
					}
				});
			});
		} else if (this.modelValue) {
			let url = this.options.getFileUrl + '/' + this.modelValue;
			let reader  = new FileReader();
			this.req('GET', url).then((response) => {
				if (response) {
					let blob = new Blob();
					let file = new File([response.body], this.modelValue);
					let fileDetail = { size: file.size, name: file.name };
					myDropzone.emit("addedfile", fileDetail);
					myDropzone.emit("thumbnail", fileDetail, url);
					myDropzone.emit("complete", fileDetail);
				}
			});
		}
	}
}
</script>