<template>
	<div class="h-100">
		<router-view v-if="isLanguageLoaded"/>
		<notifications />
	</div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { getDirection } from '@/utils'
import moment from 'moment';

export default {
	name: 'App',
	data () {
		return {
			user: false,
		}
	},
	created () {
		localStorage.setItem('apiURL', process.env.VUE_APP_API_URL);
	},
	computed: {
		isLanguageLoaded: function() {
			return this.$i18n.availableLocales.length > 0;
		}
	},
	methods: {
		...mapActions(['logout']),
	},
	beforeMount () {
		const direction = getDirection()
		if (direction.isRtl) {
			document.body.classList.add('rtl')
			document.dir = 'rtl'
			document.body.classList.remove('ltr')
		} else {
			document.body.classList.add('ltr')
			document.dir = 'ltr'
			document.body.classList.remove('rtl')
		}
	}
}
</script>
